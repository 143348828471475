body {
  font-family: 'Halant', serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  background-color: #fc6c85;
  color: #FFFFFF;
}

.App-header {
  background: #fc6c85;
  padding: 10px 0;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

nav li {
  margin: 0 15px;
}

nav a {
  text-decoration: none;
  color: #F0EDE6;
  font-weight: bold;
}

.main-section {
  text-align: center;
  padding: 50px 20px;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

p {
  font-size: 1.2em;
}

.gallery {
  padding: 30px 20px;
  text-align: center;
}

.gallery h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.card {
  display: inline-block;
  margin: 10px;
  background:  #FFE4E7 ;
  color: #E4A0B7;
  border-radius: 10px;
  padding: 15px;
  width: 200px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card p {
  font-size: 1em; /* Adjust font size as needed */
  color: #E4A0B7; /* Text color matching your theme */
  margin-top: 10px;
  word-wrap: break-word; /* Ensures long words wrap to the next line */
  text-align: center; /* Centers the text inside the card */
}

.card img {
  width: 200px; /* Set a fixed width */
  height: 250px; /* Set a fixed height */
  object-fit: cover; /* Ensures images are cropped to fill the container while maintaining aspect ratio */
  border-radius: 10px; /* Optional: Keeps the rounded corners */
}

footer {
  background: #fc6c85;
  text-align: center;
  padding: 10px 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Satisfy', cursive;
  font-weight: 400;
}

.contact-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 1.2em;
  font-weight: bold;
  text-decoration: none;
  color: #FFFFFF;
  background-color: #E4A0B7;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  /* margin-top: 20px; */
}

.contact-button .contact-icon {
  margin-right: 10px;
}

.contact-button:hover {
  background-color: #FFFFFF;
  color: #E4A0B7;
}

